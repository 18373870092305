import { MyI18n } from '@slideslive/fuse-kit/i18n';

import cs from './cs.json';
import de from './de.json';
import en from './en.json';

const myI18n = new MyI18n(gon?.locale || 'en');

myI18n.addTranslations({ ...cs, ...de, ...en });

export default myI18n.i18n;
