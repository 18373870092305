import { arrow, autoUpdate, computePosition, flip, hide, inline, offset, shift, size } from '@floating-ui/dom';
import {
  addListener,
  animationsComplete,
  createElementFromHTML,
  defineTargetValuesFromDataset,
  generateRandomId,
  isInsideDialog,
  isVariableDefinedNotNull,
  removeListener,
  resolveTwClasses,
  slideInAnimationClasses,
} from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static targets = ['item'];

  initialize() {
    this.mutationObserver = new MutationObserver(this.handleMutations.bind(this));
    this.tooltipTemplate = document.getElementById('tooltipTemplate');
    this.spinnerTemplate = document.getElementById('tooltipSpinnerTemplate');
    this.arrowTemplate = document.getElementById('tooltipArrowTemplate');
    this.tooltipCache = new WeakMap();
    this.headerHeight = 0;
  }

  connect() {
    this.headerHeight = Number(
      window.getComputedStyle(document.querySelector('html')).getPropertyValue('--header-height')?.replace(/\D/g, '') ||
        0,
    );
  }

  disconnect() {
    this.mutationObserver.disconnect();
  }

  itemTargetConnected(target) {
    if (this.isTurboPreview || this.tooltipCache.has(target)) return;

    this.initTooltip(target);
    this.removeTitleAttributeIfUsed(target);
    this.initListeners(target);
    this.initAttrsObserver(target);

    this.dispatch('connected', { target, detail: { controller: this } });
  }

  itemTargetDisconnected(target) {
    this.destroyItem(target);
  }

  destroyAllItems() {
    this.itemTargets.forEach(this.destroyItem.bind(this));
  }

  destroyItem(item) {
    const tooltipData = this.itemData(item);

    if (!tooltipData) return;

    const { destroying, delayTimeout, listeners } = tooltipData.options;
    const show = tooltipData.values.show;
    const floatingElement = tooltipData.elements.floating;

    if (destroying) return;

    tooltipData.options.destroying = true;
    clearTimeout(delayTimeout);

    listeners.forEach(({ target, id }) => removeListener(target, { id }));

    if (show) {
      this.hide(item);
      this.doHide(item, { disableAnimation: true });
    }

    floatingElement.className = resolveTwClasses(floatingElement.className, 'tw-z-tooltip');

    this.tooltipCache.delete(item);
  }

  initTooltip(item) {
    const data = this.createTooltipData(item);
    this.tooltipCache.set(item, data);
    this.createTooltipFloatingElement(item);
  }

  createTooltipData(item) {
    return {
      values: defineTargetValuesFromDataset(item, 'tooltip', {
        classes: {
          type: String,
          default: '',
        },
        skipHeaderPadding: {
          type: Boolean,
          default: false,
        },
        placement: {
          type: String,
          default: 'top',
        },
        delay: {
          type: Number,
          default: 0,
        },
        disabled: {
          type: Boolean,
          default: false,
        },
        interactive: {
          type: Boolean,
          default: false,
        },
        hideArrow: {
          type: Boolean,
          default: false,
        },
        disableAnimation: {
          type: Boolean,
          default: false,
        },
        useHideMiddleware: {
          type: Boolean,
          default: true,
        },
        hideStrategy: {
          type: String,
          default: 'referenceHidden',
          // possibleValues: ['referenceHidden', 'escaped'],
        },
        invisibleInsteadOfHide: {
          type: Boolean,
          default: false,
        },
        followCursor: {
          type: Boolean,
          default: false,
        },
        followCursorAxis: {
          type: String,
          default: undefined,
          // possibleValues: ['x', 'horizontal', 'y', 'vertical'],
        },
        toggleManually: {
          type: Boolean,
          default: false,
        },
        show: {
          type: Boolean,
          default: false,
        },
        url: {
          type: String,
          default: undefined,
        },
        content: {
          type: String,
          default: undefined,
        },
        appendTo: {
          type: String,
          default: 'body',
          // possibleValues: ['reference', <css selector>],
        },
        boundary: {
          type: String,
          default: 'clippingAncestors',
          // possibleValues: ['clippingAncestors', <css selector>],
        },
      }),
      options: {
        stylesInitialized: false,
        moveByHeader: false,
        useInlineMiddleware: false,
        listeners: [],
        cleanupFn: null,
        resolvedPlacement: null,
        delayTimeout: null,
        isMouseOver: false,
        isFocused: false,
        destroying: false,
      },
      elements: { floating: null, arrow: null },
      // props that are possible to change from outside and cannot be dataset values
      props: this.defineItemProps(item),
    };
  }

  createTooltipFloatingElement(item) {
    const tooltipData = this.itemData(item);
    const { classes, interactive } = tooltipData.values;
    const floatingElement = createElementFromHTML(this.tooltipTemplate.innerHTML);
    floatingElement.firstElementChild.className = resolveTwClasses(
      floatingElement.firstElementChild.className,
      classes,
    );

    if (!interactive) {
      floatingElement.classList.add('tw-pointer-events-none');
    }

    tooltipData.elements.floating = floatingElement;
  }

  initListeners(item) {
    const tooltipData = this.itemData(item);
    const { toggleManually, interactive, followCursor } = tooltipData.values;
    const listeners = tooltipData.options.listeners;

    if (toggleManually) return;

    this.addItemListeners(item, listeners);

    if (interactive) {
      this.addInteractiveListeners(tooltipData.elements.floating, listeners, item);
    }

    if (followCursor) {
      this.addFollowCursorListeners(item, listeners);
    }
  }

  addItemListeners(item, listeners) {
    const events = [
      { event: 'mouseenter', handler: this.showOnMouseEnter },
      { event: 'focusin', handler: this.showOnFocusIn },
      { event: 'mouseleave', handler: this.hideOnMouseLeave },
      { event: 'focusout', handler: this.hideOnFocusOut },
    ];

    events.forEach(({ event, handler }) => {
      listeners.push({
        target: item,
        id: addListener(item, event, handler.bind(this, item)),
      });
    });
  }

  addInteractiveListeners(floatingElement, listeners, item) {
    ['mouseleave', 'focusout'].forEach((event) => {
      listeners.push({
        target: floatingElement,
        id: addListener(floatingElement, event, this.hideOnMouseLeave.bind(this, item)),
      });
    });
  }

  addFollowCursorListeners(item, listeners) {
    listeners.push(
      {
        target: window,
        id: addListener(window, 'scroll', this.handleFollowCursor.bind(this, item)),
      },
      {
        target: item,
        id: addListener(item, 'mousemove', this.handleFollowCursor.bind(this, item)),
      },
    );
  }

  initAttrsObserver(item) {
    this.mutationObserver.observe(item, { attributes: true });
  }

  handleMutations(mutations) {
    mutations.forEach(({ type, attributeName, target }) => {
      const tooltipData = this.itemData(target);

      if (!tooltipData || tooltipData.options.destroying) return;

      if (type === 'attributes') {
        switch (attributeName) {
          case 'title':
            this.removeTitleAttributeIfUsed(target, { override: true });
            break;
          case 'data-tooltip-content':
            this.contentChanged(target);
            break;
          case 'data-tooltip-show':
            this.showValueChanged(target);
            break;
          case 'data-tooltip-disabled':
            this.disabledValueChanged(target);
            break;
          default:
            break;
        }
      }
    });
  }

  contentChanged(item) {
    this.itemProps(item).resolvedContent = null;
  }

  showValueChanged(item) {
    const tooltipData = this.itemData(item);
    const show = tooltipData.values.show;

    if (show === !!tooltipData.options.cleanupFn) return;

    if (show) {
      this.doShow(item);
    } else {
      this.doHide(item);
    }
  }

  disabledValueChanged(item) {
    const tooltipData = this.itemData(item);
    const { isMouseOver, isFocused } = tooltipData.options;

    if (tooltipData.values.disabled) {
      item.tabIndex = -1;
      this.hide(item);
    } else if (isMouseOver || isFocused) {
      item.tabIndex = 0;
      this.show(item);
    }
  }

  updateArrowPosition(item, { x, y }) {
    const tooltipData = this.itemData(item);
    const { floating: floatingElement, arrow: arrowElement } = tooltipData.elements;

    if (!arrowElement) return;

    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[tooltipData.options.resolvedPlacement.split('-')[0]];
    const rotateDeg = { top: 180, left: 90, right: -90 }[staticSide] || 0;

    if (['top', 'bottom'].includes(staticSide)) {
      x = Math.max(8, Math.min(x, floatingElement.offsetWidth - arrowElement.offsetWidth - 8));
    } else if (['right', 'left'].includes(staticSide)) {
      y = Math.max(8, Math.min(y, floatingElement.offsetHeight - arrowElement.offsetHeight - 8));
    }

    Object.assign(arrowElement.style, {
      left: isVariableDefinedNotNull(x) ? `${x}px` : '',
      top: isVariableDefinedNotNull(y) ? `${y}px` : '',
      right: '',
      bottom: '',
      transform: `rotate(${rotateDeg}deg)`,
      [staticSide]: `-${arrowElement.offsetWidth / 2}px`,
    });
  }

  async updatePosition(item) {
    const tooltipData = this.itemData(item);

    if (!tooltipData) return;

    const { placement, toggleManually, invisibleInsteadOfHide } = tooltipData.values;
    const { floating: floatingElement } = tooltipData.elements;
    const { resolvedPlacement } = tooltipData.options;

    const {
      x,
      y,
      placement: computedPlacement,
      middlewareData,
    } = await computePosition(tooltipData.props.virtualElement || item, floatingElement, {
      placement,
      middleware: this.middleware(item),
    });

    if (middlewareData.hide) {
      const { referenceHidden, escaped } = middlewareData.hide;

      if (referenceHidden || escaped) {
        if (invisibleInsteadOfHide) {
          floatingElement.classList.add('tw-invisible');
        } else {
          this.hide(item);

          if (toggleManually) {
            this.doHide(item);
          }
        }

        return;
      }

      if (invisibleInsteadOfHide) {
        floatingElement.classList.remove('tw-invisible');
      }
    }

    if (resolvedPlacement !== computedPlacement) {
      floatingElement.classList.remove(...this.paddingClasses(item, resolvedPlacement));
      floatingElement.classList.add(...this.paddingClasses(item, computedPlacement));
      floatingElement.firstElementChild.classList.remove(...this.animationClasses(item, resolvedPlacement));
      floatingElement.firstElementChild.classList.add(...this.animationClasses(item, computedPlacement));

      tooltipData.options.resolvedPlacement = computedPlacement;
    }

    Object.assign(floatingElement.style, { transform: `translate(${x}px, ${y}px)` });

    if (middlewareData.arrow) {
      this.updateArrowPosition(item, middlewareData.arrow);
    }
  }

  updateFloatingElementContent(item) {
    const tooltipData = this.itemData(item);
    const { resolvedContent } = tooltipData.props;
    const { floating: floatingElement } = tooltipData.elements;

    if (!floatingElement || !resolvedContent) return;

    const contentContainer = floatingElement.firstElementChild;
    contentContainer.innerHTML = '';

    if (typeof resolvedContent === 'string') {
      contentContainer.innerHTML = resolvedContent;
    } else {
      contentContainer.appendChild(resolvedContent);
    }

    if (!tooltipData.values.hideArrow) {
      tooltipData.elements.arrow = createElementFromHTML(this.arrowTemplate.innerHTML);
      contentContainer.appendChild(tooltipData.elements.arrow);
    } else {
      tooltipData.elements.arrow = null;
    }
  }

  handleFollowCursor(item, event) {
    const tooltipData = this.itemData(item);
    const virtualElement = tooltipData.props.virtualElement;
    let newX;
    let newY;

    if (event.type === 'scroll') {
      if (!virtualElement) return;

      const rect = virtualElement.getBoundingClientRect();

      newX = rect.x;
      newY = rect.y;
    } else {
      newX = event.clientX;
      newY = event.clientY;
    }

    switch (tooltipData.values.followCursorAxis) {
      case 'horizontal':
      case 'x':
        newY = item.getBoundingClientRect().y;
        break;
      case 'vertical':
      case 'y':
        newX = item.getBoundingClientRect().x;
        break;
      case 'true':
        break;
      default:
        return;
    }

    tooltipData.props.virtualElement = {
      getBoundingClientRect() {
        return {
          width: 0,
          height: 0,
          x: newX,
          y: newY,
          left: newX,
          right: newX,
          top: newY,
          bottom: newY,
        };
      },
    };
  }

  showOnMouseEnter(item) {
    const tooltipData = this.itemData(item);

    if (tooltipData.values.disabled) return;

    const { isFocused, isMouseOver } = tooltipData.options;

    if (isMouseOver) return;

    tooltipData.options.isMouseOver = true;

    if (isFocused) {
      tooltipData.options.isFocused = false;

      return;
    }

    this.show(item);
  }

  showOnFocusIn(item) {
    const tooltipData = this.itemData(item);

    if (tooltipData.values.disabled) return;

    const { isFocused, isMouseOver } = tooltipData.options;

    if (isFocused || isMouseOver) return;

    tooltipData.options.isFocused = true;

    this.show(item);
  }

  hideOnMouseLeave(item, event) {
    if (!this.shouldHideBasedOnRelatedTarget(item, event.relatedTarget)) return;

    const tooltipData = this.itemData(item);

    if (tooltipData.values.disabled) return;

    tooltipData.options.isMouseOver = false;

    if (tooltipData.options.isFocused) return;

    this.hide(item);
  }

  hideOnFocusOut(item, event) {
    if (!this.shouldHideBasedOnRelatedTarget(item, event.relatedTarget)) return;

    const tooltipData = this.itemData(item);

    if (tooltipData.values.disabled) return;

    tooltipData.options.isFocused = false;

    if (tooltipData.options.isMouseOver) return;

    this.hide(item);
  }

  clearDelay(item) {
    const tooltipData = this.itemData(item);

    clearTimeout(tooltipData.options.delayTimeout);
    tooltipData.options.delayTimeout = null;
  }

  runDelay(item) {
    const tooltipData = this.itemData(item);
    this.clearDelay(item);

    return new Promise((resolve) => {
      tooltipData.options.delayTimeout = setTimeout(() => {
        tooltipData.options.delayTimeout = null;
        resolve();
      }, tooltipData.values.delay);
    });
  }

  async doShow(item) {
    const tooltipData = this.itemData(item);
    const { resolvedContent, appendTo, virtualElement } = tooltipData.props;
    const { floating: floatingElement } = tooltipData.elements;

    if (!tooltipData.options.stylesInitialized) {
      this.initializeStyles(item, tooltipData);
    }

    await this.runDelay(item);

    if (!resolvedContent || tooltipData.values.disabled) return;

    appendTo.appendChild(floatingElement);
    tooltipData.options.cleanupFn = autoUpdate(
      virtualElement || item,
      floatingElement,
      this.updatePosition.bind(this, item),
    );

    this.dispatch('show', { target: item });
  }

  initializeStyles(item, tooltipData) {
    const { floating: floatingElement } = tooltipData.elements;

    if (item.closest('header')) {
      floatingElement.className = resolveTwClasses(floatingElement.className, 'tw-z-top');
    } else if (!tooltipData.values.skipHeaderPadding && !isInsideDialog(item)) {
      tooltipData.options.moveByHeader = true;
    }

    tooltipData.options.useInlineMiddleware = window.getComputedStyle(item).display === 'inline';
    tooltipData.options.stylesInitialized = true;
  }

  show(item) {
    const tooltipData = this.itemData(item);
    if (tooltipData.values.toggleManually) return;

    tooltipData.values.show = true;
  }

  async doHide(item, { disableAnimation = false } = {}) {
    const tooltipData = this.itemData(item);
    const { floating: floatingElement } = tooltipData.elements;
    const { cleanupFn, resolvedPlacement } = tooltipData.options;

    this.clearDelay(item);
    cleanupFn?.();
    tooltipData.options.cleanupFn = null;
    tooltipData.options.resolvedPlacement = null;

    if (!floatingElement) return;

    this.dispatch('hide', { target: item });

    if (!disableAnimation && !tooltipData.values.disableAnimation) {
      floatingElement.firstElementChild.classList.add('hiding');

      await animationsComplete(floatingElement.firstElementChild);

      if (!this.tooltipCache.has(item) || tooltipData.options.destroying) return;
    }

    floatingElement.remove();

    if (!disableAnimation && !tooltipData.values.disableAnimation) {
      floatingElement.firstElementChild.classList.remove('hiding');
      floatingElement.firstElementChild.classList.remove(...this.animationClasses(item, resolvedPlacement));
    }
  }

  hide(item) {
    const tooltipData = this.itemData(item);
    if (tooltipData.values.toggleManually) return;

    tooltipData.values.show = false;
  }

  removeTitleAttributeIfUsed(item, { override = false } = {}) {
    const tooltipData = this.itemData(item);

    if (item.title && (!tooltipData.values.content || override)) {
      tooltipData.values.content = item.title;
    }

    item.removeAttribute('title');
  }

  animationClasses(item, placement) {
    const disableAnimation = this.itemValues(item).disableAnimation;

    if (disableAnimation) return [];

    return slideInAnimationClasses(placement);
  }

  paddingClasses(item, placement) {
    const hideArrow = this.itemValues(item).hideArrow;

    if (hideArrow || !placement) return [];

    const side = placement.split('-')[0];
    const classes = {
      top: ['tw-pb-2.5'],
      bottom: ['tw-pt-2.5'],
      left: ['tw-pr-2.5'],
      right: ['tw-pl-2.5'],
    };

    return classes[side] || [];
  }

  shouldHideBasedOnRelatedTarget(item, relatedTarget) {
    const floatingElement = this.itemElements(item).floating;

    return (
      item !== relatedTarget &&
      !item.contains(relatedTarget) &&
      floatingElement !== relatedTarget &&
      !floatingElement.contains(relatedTarget)
    );
  }

  middleware(item) {
    const tooltipData = this.itemData(item);
    const { hideArrow, useHideMiddleware, hideStrategy } = tooltipData.values;
    const { arrow: arrowElement, floating: floatingElement } = tooltipData.elements;
    const middleware = [];

    if (tooltipData.options.useInlineMiddleware) {
      middleware.push(inline());
    }

    middleware.push(
      offset(hideArrow ? 4 : 0),
      shift(this.detectOverflowOptions(item)),
      flip(this.detectOverflowOptions(item)),
    );

    if (arrowElement) {
      middleware.push(arrow({ element: arrowElement }));
    }

    middleware.push(
      size({
        ...this.detectOverflowOptions(item),
        apply: ({ availableWidth, availableHeight }) => {
          Object.assign(floatingElement.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight}px`,
          });
        },
      }),
    );

    if (useHideMiddleware) {
      middleware.push(hide({ ...this.detectOverflowOptions(item), strategy: hideStrategy }));
    }

    return middleware;
  }

  detectOverflowOptions(item) {
    const tooltipData = this.itemData(item);
    const { boundary, rootBoundary } = tooltipData.props;

    const padding = {
      top: tooltipData.options.moveByHeader ? this.headerHeight + 4 : 4,
      bottom: 4,
      left: 4,
      right: 4,
    };

    return {
      padding,
      rootBoundary,
      boundary,
    };
  }

  defineItemProps(item) {
    const self = this;
    const values = {};

    return {
      get resolvedContent() {
        if (!values.resolvedContent) {
          const { url, content } = self.itemValues(item);

          if (url && url.trim() !== '') {
            const frameId = generateRandomId(12);
            const spinnerHtml = self.spinnerTemplate?.innerHTML || `<div class="tw-p-4">Loading&hellip;</div>`;
            values.resolvedContent = createElementFromHTML(
              `<turbo-frame id="${frameId}" src="${url}?frame_id=${frameId}" loading="lazy">${spinnerHtml}</turbo-frame>`,
            );
          } else {
            values.resolvedContent = content;
          }

          if (values.resolvedContent) {
            self.updateFloatingElementContent(item);
          }
        }

        return values.resolvedContent || null;
      },
      set resolvedContent(value) {
        values.resolvedContent = value;

        self.updateFloatingElementContent(item);

        if (self.itemValues(item).show) {
          self.updatePosition(item);
        }
      },

      get virtualElement() {
        return values.virtualElement || null;
      },
      set virtualElement(value) {
        values.virtualElement = value;

        if (self.itemValues(item).show) {
          self.updatePosition(item);
        }
      },

      get appendTo() {
        if (!values.appendTo) {
          const appendTo = self.itemValues(item).appendTo;

          if (appendTo === 'reference') {
            values.appendTo = item;
          } else if (appendTo === 'body' || isInsideDialog(item)) {
            values.appendTo = isInsideDialog(item) ? item.closest('dialog') : document.body;
          } else if (appendTo) {
            values.appendTo = item.closest(appendTo) || document.querySelector(appendTo);
          }
        }

        if (document.fullscreenElement && !document.fullscreenElement.contains(values.appendTo)) {
          return document.fullscreenElement;
        }

        return values.appendTo || null;
      },
      set appendTo(value) {
        const tooltipData = self.itemData(item);
        const show = tooltipData.values.show;
        const floatingElement = tooltipData.elements.floating;

        if (values.appendTo && show) {
          floatingElement.remove();
        }

        values.appendTo = value;

        if (show) {
          value.appendChild(floatingElement);
          self.updatePosition(item);
        }
      },

      get rootBoundary() {
        return values.rootBoundary || 'viewport';
      },
      set rootBoundary(value) {
        values.rootBoundary = value;

        if (self.itemValues(item).show) {
          self.updatePosition(item);
        }
      },

      get boundary() {
        if (!values.boundary) {
          const boundary = self.itemValues(item).boundary;

          values.boundary = item.closest(boundary) || document.querySelector(boundary) || boundary;
        }

        return values.boundary || null;
      },
      set boundary(value) {
        values.boundary = value;

        if (self.itemValues(item).show) {
          self.updatePosition(item);
        }
      },
    };
  }

  itemData(item) {
    return this.tooltipCache.get(item);
  }

  itemValues(item) {
    return this.itemData(item)?.values;
  }

  itemProps(item) {
    return this.itemData(item)?.props;
  }

  itemOptions(item) {
    return this.itemData(item)?.options;
  }

  itemElements(item) {
    return this.itemData(item)?.elements;
  }
}
