import { animationsComplete, isVisible } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  async processChange(event) {
    const {
      target: { action, target: targetId, targets: targetsSelector },
    } = event;

    if (
      event.target.firstElementChild instanceof HTMLTemplateElement &&
      event.target.templateElement.content.children.length > 0
    ) {
      for (const newElement of event.target.templateElement.content.children) {
        this.animateNewElement(newElement);
      }
    }

    if (['before', 'after', 'append', 'prepend'].includes(action)) {
      return;
    }

    const elementsToUpdate = this.elementsToUpdate(action, targetId, targetsSelector);

    if (elementsToUpdate.length === 0) {
      return;
    }

    event.preventDefault();

    let animatedElementsCount = 0;

    const performActionIfAllAnimationsCompleted = () => {
      if (++animatedElementsCount < elementsToUpdate.length) {
        return;
      }

      event.target.performAction();

      if (action === 'remove') {
        return;
      }

      for (const elementToUpdate of elementsToUpdate) {
        const leaveAnimationClass = elementToUpdate.dataset.turboStreamLeaveClass;

        if (!leaveAnimationClass || !isVisible(elementToUpdate)) {
          continue;
        }

        elementToUpdate.classList.remove(leaveAnimationClass);
      }
    };

    for (const elementToUpdate of elementsToUpdate) {
      this.animateOldElement(elementToUpdate, performActionIfAllAnimationsCompleted);
    }
  }

  animateNewElement(element) {
    if (!element) {
      return;
    }

    const enterAnimationClass = element.dataset.turboStreamEnterClass;

    if (enterAnimationClass) {
      element.classList.add(enterAnimationClass);

      animationsComplete(element).then(() => {
        element.classList.remove(enterAnimationClass);
      });
    }
  }

  animateOldElement(element, callback) {
    const leaveAnimationClass = element.dataset.turboStreamLeaveClass;

    if (!leaveAnimationClass || !isVisible(element)) {
      callback();
      return;
    }

    element.classList.add(leaveAnimationClass);

    animationsComplete(element).then(callback.bind(this));
  }

  elementsToUpdate(action, targetId, targetsSelector) {
    const elementById = document.getElementById(targetId);
    let elementsToUpdate = elementById ? [elementById] : document.querySelectorAll(targetsSelector);

    if (action === 'update') {
      const parentElements = elementsToUpdate;
      elementsToUpdate = [];

      for (const parentElement of parentElements) {
        elementsToUpdate = [...elementsToUpdate, ...parentElement.children];
      }
    }

    return elementsToUpdate;
  }
}
